import { render, staticRenderFns } from "./XiuXue.vue?vue&type=template&id=a30ceb60&scoped=true"
import script from "./XiuXue.vue?vue&type=script&lang=js"
export * from "./XiuXue.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a30ceb60",
  null
  
)

export default component.exports